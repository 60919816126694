<template>
    <div>
        <TableList
            :form_data_seo.sync="form_data_seo"
            :columns="columns"
            :get_table_list="this.get_table_list"
			@expandedRowsChange="handleExpandedRowsChange($event)"
            ref="list"
            :expandRowByClick="true"
            :expandedRowRender="true"
            :submit_preprocessing="submit_preprocessing"
			:slot_table_list="['operation']"
			rowKey="id"
			:scroll="{x:2000}"
        >
			<template slot="right_btn">
				<!-- <a :href="'/manage/crm.export/lineExport' | export_form" target="_blank">
					<a-button type="primary" class="a_btn">导出</a-button>
				</a> -->
                <a-popconfirm title="您确定要导出该报表吗？" ok-text="确认" cancel-text="取消" @confirm="export_excel">
					<a-button class="a_btn">导出</a-button>
				</a-popconfirm>
            </template>
            <template slot="expandedRowRender" slot-scope="data">
                <a-table
                    :columns="columns_sub"
                    :pagination="false"
                    :dataSource="data.record.list"
                    style="margin: 0"
                >
                </a-table>
            </template>
        </TableList>
        <PopTableList
            rowKey="id"
            ref="pop_table_list"
            :is_created_get_list="false"
            :columns="columns_pop"
            :get_table_list="get_table_list_pop"
            :form_data_seo="form_data_seo"
            :visible.sync="visible"
            :slot_table_list="['address']"
            @submit="pop_table_submit"
        >
            <template
                slot="address"
                slot-scope="data"
            >{{data.record.province + data.record.city + data.record.district + data.record.address}}</template>

			
        </PopTableList>
    </div>
</template>

<script>
// @ is an alias to /src
import EditPop from "@/components/EditPop";
import Form from "@/components/Form";
import {getTripVisitRecord,getTripList} from "@/api/customer";
import {exportTable } from '@/api/re'
import TableList from "@/components/TableList";
import PopTableList from "@/components/PopTableList";
import SelectUser from "@/components/SelectUser";

const columns = [
    {
        title: "员工ID",
        dataIndex: "user_id",
    },
    {
        title: "操作人",
        dataIndex: "user_name",
    },
    {
        title: "部门",
        dataIndex: "department",
    },
    {
        title: "岗位",
        dataIndex: "role",
    },
    {
        title: "出差事由",
        dataIndex: "remark",
	},
    
    {
        title: "出发地点",
        dataIndex: "start_place_name",
	},
    
    {
        title: "开始时间",
        dataIndex: "start_time",
	},
    
    {
        title: "到达地点",
        dataIndex: "end_place_name",
	},
    
    {
        title: "到达时间",
        dataIndex: "end_time",
	},
    
    // {
    //     title: "出发打卡地点",
    //     dataIndex: "start_clock",
	// },
    
    // {
    //     title: "出发打卡时间",
    //     dataIndex: "start_clock_time",
	// },
    
    // {
    //     title: "到达打卡地点",
    //     dataIndex: "end_clock",
	// },
    
    // {
    //     title: "到达打卡时间",
    //     dataIndex: "end_clcok_time",
	// },
];

const columns_sub = [
    {
        title: "客户ID",
        dataIndex: "customer_id",
    },
    {
        title: "客户名称",
        dataIndex: "customer",
    },
    {
        title: "客户类型",
        dataIndex: "group",
    },
    {
        title: "客户地址",
        dataIndex: "address",
    },
    {
        title: "标签",
        dataIndex: "type",
    },
    {
        title: "采集照片",
        dataIndex: "photo_num",
    },
    {
        title: "拜访时长",
        dataIndex: "duration",
    },
    {
        title: "拜访时间",
        dataIndex: "a_time",

    },
    {
        title: "到达时间",
        dataIndex: "arrive_time",
    },
    {
        title: "到达打卡地点",
        dataIndex: "arrive_address",
    },
    {
        title: "结束时间",
        dataIndex: "leave_time",
    },
    {
        title: "结束打卡地点",
        dataIndex: "leave_address",
    },
    {
        title: "城乡类型",
        dataIndex: "town_type",
    },
    {
        title: "所属乡镇",
        dataIndex: "town",
    },
];


let type_list = [
    {
        key: 0,
        value: '计划拜访'
    },
    {
        key: 1,
        value: '临时拜访'
    },
    {
        key: 2,
        value: '指定拜访'
    },
    {
        key: 3,
        value: '巡查'
    },
];

export default {
    name: "Index",
    components: {
        EditPop,
        Form,
        TableList,
		PopTableList,
		SelectUser
    },
    data() {
        return {
			expandedRowKeys:['id','user_id'],
			line_set_visible:false,
            get_table_list: getTripList,
            get_table_list_pop: getTripVisitRecord,
            columns,
            columns_sub,
			visible: false,
            submit_preprocessing: {
                array_to_string: ['department','role']
            },
            form_data_seo: {
                list: [
                    {
                        type: "tree-select",
                        name: "department",
                        title: "部门",
                        options: {},
                        treeData: [],
                        multiple: true
                    },
                    {
                        type: "tree-select",
                        name: "role",
                        title: "岗位职务",
                        options: {},
                        treeData: [],
                        multiple: true
                    },
                    // {
                    //     type: "select",
                    //     name: "role",
                    //     title: "岗位职务",
                    //     mode: "default",
                    //     options: {},
                    //     list: []
                    // },
                    {
                        type: "text",
                        name: "keyword",
                        title: "姓名",
                        placeholder: '姓名/手机号',
                        options: {

                        }
					},
                    // {
                    //     type: "text",
                    //     name: "customer",
                    //     title: "客户",
                    //     placeholder: '客户名称/ID/地址',
                    //     options: {

                    //     }
                    // },
					// {
					//     type: "cascader",
					//     name: "group_id",
					//     title: "客户类型",
					//     list: [],
					//     placeholder: "请选择客户类型",
					//     mode: "default",
					//     options: {}
					// },
                    // {
                    //     type: 'select',
                    //     name: 'type',
                    //     title: '标签',
                    //     list: type_list,
                    //     options: {},
					// },
                    {
                        type: "range_date",
                        name: "range_date",
                        title: "日期",
                        options: {},
                        start: {
                            name: 'start_time'
                        },
                        end: {
                            name: 'end_time'
                        },
                    },
                ],
                ...this.$config.form_data_seo
            },
            form_data: {}
        };
    },
    created() {
        this.$method.get_department().then(res => {
            this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'department', 'treeData', res)
        });
        // this.$method.get_role().then(res => {
        //     this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'role', 'list', res)
        // });
        this.$method.get_role_tree().then(res => {
			this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'role', 'treeData', res)
		});
        this.$method.get_group().then(res => {
			this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'group_id', 'list', res)
		});

    },
    methods: {
        pop_table_submit() {
            this.visible = false;
		},
		
        //获取子集信息
		get_info(id,user_id){
			getTripVisitRecord({
				data: {
					id,
                    user_id
				}
			}).then(res => {
				let list = [];
				this.$refs.list.list.forEach((vo, index) => {
					if (vo.id == id) {
						vo.list = res.data.list;
					}
					list.push(vo)
				})
				this.$refs.list.set_data('list', list)
			})
		},
        async handleExpandedRowsChange(e) {
            e.forEach(item => {
				let index = this.$refs.list.list.findIndex(v=>{
					return v.id == item
				})
                if (!this.$refs.list.list[index].list) {
                    this.get_info(this.$refs.list.list[index].id,this.$refs.list.list[index].user_id)
                }
            })
        },
        export_excel(){
				exportTable({
					data:{
						field:[],// 选择了哪些字段
						export_type:'trip_visit',// 选择了哪个维度
						...this.$refs.list.seo_data, // 筛选项表单值
					},
					info:true,
					loading:true
				})
			},
    }
};
</script>

<style lang="less">
@import url("../../assets/less/app.less");
</style>